import axios from 'axios';
import Vue from 'vue';
import Toasted from 'vue-toasted';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import { getBrowseInfo } from './helpers/utils';
Vue.use(Toasted);
Vue.use(Vuex);

const FACILITATORS_ENDPOINT = 'foundation/facilitators';
const INVITES_ENDPOINT = 'onboarding/invites';

const unauthorizedMessage = 'Unauthorized, redirecting to the login.';

const defaultMessage = 'Server side error, please contact the support team.';

export default new Vuex.Store({
  state: {
    platformName: '',
    initiateFrom: '',
    token: '',
    smsCode: '',
    stepProgress: {
      step1: 'progress',
      step2: '',
      step3: '',
      step4: '',
      step5: ''
    },
    flowCompleted: { status: false },
    flowStatus: null, //approved or not-approved,
    identity: {},
    smApplicationId: null,
    payoutAccount: {},
    fees: {},
    facilitator: undefined,
    invite: null,
    saving: false,
    termsOpen: false,
    privacyOpen: false,
    overrideBackButton: false,
    backButtonPressed: false
  },
  mutations: {
    SET_IDENTITY(state, data) {
      state.identity = data;
    },
    SET_SM_APPLICATION_ID(state, data) {
      state.smApplicationId = data;
    },
    SET_PAYOUT(state, data) {
      state.payoutAccount = data;
    },
    SET_ITEM(state, item, value) {
      this.state['item'] = value;
    },
    setStepProgress(state, { step, status }) {
      const nextStep = step + 1;
      let previosStep = step - 1;
      if (step == 'default') {
        Object.assign(this.state.stepProgress, {
          step1: 'progress',
          step2: '',
          step3: '',
          step4: '',
          step5: ''
        });
      } else if (state.stepProgress[`step${step}`] != 'completed') {
        while (previosStep > 0) {
          this.state.stepProgress[`step${previosStep}`] = 'completed';
          previosStep--;
        }
        this.state.stepProgress[`step${step}`] = status;
        if (nextStep <= 5 && status == 'completed') {
          this.state.stepProgress[`step${nextStep}`] = 'progress';
        }
      }
    },
    setFlowStatus(state, { flowCompleted, flowStatus }) {
      this.state.flowCompleted = {
        status: flowCompleted
      };
      this.state.flowStatus = flowStatus;
    },
    logError(state, params) {
      const { errorMessage, errorStatus } = params;
      const message =
        errorStatus === 401 ? unauthorizedMessage : errorMessage || defaultMessage;
      Vue.toasted.show(message, {
        type: 'error',
        duration: 6000,
        position: 'top-right'
      });
    },
    SET_INITIATE_FROM(state, data) {
      state.initiateFrom = data;
    },
    SET_FEES(state, data) {
      state.fees = data;
    },
    SET_FACILITATOR(state, data) {
      state.facilitator = data;
    },
    SET_INVITE(state, data) {
      state.invite = data;
    },
    SET_SAVE_AND_EXIT(state, value) {
      state.saving = value;
    },
    SET_TERMS_OPEN(state, value) {
      state.termsOpen = value;
    },
    SET_PRIVACY_OPEN(state, value) {
      state.privacyOpen = value;
    },
    SET_OVERRIDE_BACK_BUTTON(state, value) {
      state.overrideBackButton = value;
      state.backButtonPressed = false;
    },
    SET_BACK_BUTTON_PRESSED(state, value) {
      state.backButtonPressed = value;
      if (value) {
        state.overrideBackButton = false;
      }
    }
  },
  actions: {
    async GET_BROWSER_INFO() {
      return await getBrowseInfo();
    },
    GET_IDENTITY({ commit }) {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await axios({
            url: '/onboarding/accounts/identity',
            method: 'GET',
            headers: {
              'content-type': 'application/json',
              authorization: `Bearer ${localStorage.getItem('token')}`
            }
          });

          const { identity } = response.data;
          commit('SET_IDENTITY', identity);
          commit('SET_SM_APPLICATION_ID', response.data._id);
          resolve();
        } catch (error) {
          console.error(error);
          reject('GET_IDENTITY_ERROR');
        }
      });
    },
    GET_PAYOUT_ACCOUNT({ commit, state }, addAsterisks) {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await axios({
            url: `/onboarding/accounts/${state.smApplicationId}/payout`,
            method: 'GET',
            headers: {
              'content-type': 'application/json',
              authorization: `Bearer ${localStorage.getItem('token')}`
            },
            params: { addAsterisks }
          });
          commit('SET_PAYOUT', response.data);
          resolve();
        } catch (error) {
          console.error({ error });
          reject('SET_PAYOUT_ERROR');
        }
      });
    },
    SET_INITIATE_FROM({ commit }, initiateFrom) {
      commit('SET_INITIATE_FROM', initiateFrom);
    },
    GET_FEES({ commit }) {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await axios({
            url: `/onboarding/accounts/fees`,
            method: 'GET',
            headers: {
              'content-type': 'application/json',
              authorization: `Bearer ${localStorage.getItem('token')}`
            }
          });
          commit('SET_FEES', response.data);
          resolve();
        } catch (error) {
          reject('SET_FEES_ERROR');
        }
      });
    },
    async GET_FACILITATOR({ commit }) {
      try {
        const response = await axios.get(FACILITATORS_ENDPOINT);
        if (response.data && response.data.success) {
          const facilitator = response.data.data;

          document.querySelector('link[rel="icon"]').href = facilitator.branding.favIcon;
          document.documentElement.style.setProperty(
            '--theme-primary-color',
            facilitator.branding.color
          );
          commit('SET_FACILITATOR', facilitator);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async GET_INVITE({ commit }, { inviteId, facId, spProductSlug }) {
      try {
        const response = await axios.get(INVITES_ENDPOINT, {
          params: { inviteId, facId, spProductSlug }
        });
        if (response.data && response.data.success) {
          const invite = response.data.data;
          commit('SET_INVITE', invite);
        }
      } catch (error) {
        console.log(error);
      }
    },
    ACTIVATE_SAVE_AND_EXIT({ commit }) {
      commit('SET_SAVE_AND_EXIT', true);
      setTimeout(() => {
        commit('SET_SAVE_AND_EXIT', false);
      }, 1000);
    },
    TOGGLE_TERMS({ commit, state }, value) {
      commit('SET_TERMS_OPEN', value);
    },
    TOGGLE_PRIVACY({ commit, state }, value) {
      commit('SET_PRIVACY_OPEN', value);
    },
    SET_OVERRIDE_BACK_BUTTON({ commit }, value) {
      return new Promise((resolve) => {
        commit('SET_OVERRIDE_BACK_BUTTON', value);
        commit('SET_BACK_BUTTON_PRESSED', false);
        resolve();
      });
    },
    SET_BACK_BUTTON_PRESSED({ commit }, value) {
      commit('SET_BACK_BUTTON_PRESSED', value);
      if (value) {
        commit('SET_OVERRIDE_BACK_BUTTON', false);
      }
    }
  },
  getters: {
    getIdentity: state => state.identity,
    getSmApplicationId: state => state.smApplicationId,
    getPayout: state => state.payoutAccount,
    getInitiateStep: state => state.initiateFrom,
    getFees: state => state.fees,
    getFacilitator: state => state.facilitator,
    getInvite: state => state.invite,
    getSaveAndExit: state => state.saving,
    isTermsOpen: state => state.termsOpen,
    isPrivacyOpen: state => state.privacyOpen,
    shouldOverrideBackButton: (state) => state.overrideBackButton,
    isBackButtonPressed: (state) => state.backButtonPressed
  },
  plugins: [
    createPersistedState({
      paths: ['stepProgress', 'flowCompleted', 'flowStatus', 'initiateFrom']
    })
  ]
});