import GetStarted from '@/pages/GetStarted';
import InitiateFailure from '@/pages/InitiateFailure';
import Bowser from 'bowser';
import Vue from 'vue';
import Router from 'vue-router';
// import Welcome from '@/pages/Welcome';
import ContinueAccount from '@/pages/ContinueAccount';
import MobileCodeVerification from '@/pages/MobileCodeVerification';
import MerchantType from '@/pages/MerchantType';
import CompanyForm from '@/pages/CompanyForm';
import VerifyOwnership from '@/pages/VerifyOwnership';
import BankForm from '@/pages/BankForm';
import Checks from '@/pages/Checks';
import AccountApproved from '@/pages/AccountApproved';
import AccountNotApproved from '@/pages/AccountNotApproved';
import ApplicationSaved from '@/pages/ApplicationSaved';
import ConfirmProfile from '@/pages/ConfirmProfile';
import InviteExpired from '@/pages/InviteExpired';
import UnsupportedBrowser from '@/pages/UnsupportedBrowser';
import BaseLayout from '../components/BaseLayout.vue';
import Footer from '../components/Footer.vue';
import Header from '../components/Header.vue';
import checkProductStatus from './middlewares/checkProductStatus';
import { debounce } from '../helpers/utils';

// import IndividualSubForm from '@/components/IndividualSubForm';
// import ContactForm from '@/components/ContactForm';

const browser = Bowser.getParser(window.navigator.userAgent);
Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'InitiateFailure',
      component: InitiateFailure,
    },
    {
      path: '/unsupported',
      name: 'UnsupportedBrowser',
      component: UnsupportedBrowser,
      beforeEnter: (to, from, next) => {
        if (browser.getBrowserName() !== 'Internet Explorer') return next('/');
        next();
      }
    },
    {
      path: '/:spProductSlug',
      component: BaseLayout,
      children: [
        {
          path: 'sms',
          name: 'MobileCodeVerification',
          components: {
            default: MobileCodeVerification,
            Footer,
            Header
          },
          meta: {
            middleware: [checkProductStatus],
            currentStep: 0
          }
        },
        {
          path: 'account-type',
          name: 'MerchantType',
          components: {
            default: MerchantType,
            Footer,
            Header
          },
          meta: {
            middleware: [checkProductStatus],
            currentStep: 1
          }
        },
        {
          path: 'account-company',
          name: 'CompanyForm',
          components: {
            default: CompanyForm,
            Footer,
            Header
          },
          meta: {
            middleware: [checkProductStatus],
            currentStep: 1
          }
        },
        {
          path: 'verify-ownership',
          name: 'VerifyOwnership',
          components: {
            default: VerifyOwnership,
            Footer,
            Header
          },
          meta: {
            middleware: [checkProductStatus],
            currentStep: 2
          }
        },
        // {
        //   path: '/individual-sub-form',
        //   name: 'IndividualSubForm',
        //   component: IndividualSubForm,
        //   meta: {
        //     middleware: [checkProductStatus]
        //   }
        // },
        // {
        //   path: '/contact-form',
        //   name: 'ContactForm',
        //   component: ContactForm,
        //   meta: {
        //     middleware: [checkProductStatus]
        //   }
        // },
        {
          path: 'bank-account',
          name: 'BankForm',
          components: {
            default: BankForm,
            Footer,
            Header
          },
          meta: {
            middleware: [checkProductStatus],
            currentStep: 3
          }
        },
        {
          path: 'reviewing',
          name: 'Checks',
          components: {
            default: Checks,
            Footer
          },
          props: true
        },
        {
          path: 'confirm',
          name: 'ConfirmProfile',
          components: {
            default: ConfirmProfile,
            Footer,
            Header
          },
          meta: {
            middleware: [checkProductStatus],
            currentStep: 4
          }
        },
        {
          path: 'account-approved',
          name: 'AccountApproved',
          components: {
            default: AccountApproved,
            Footer
          },
          meta: { requiresAuth: true }
        },
        {
          path: 'account-for-review',
          name: 'AccountNotApproved',
          component: AccountNotApproved
        },
        {
          path: 'saved',
          name: 'ApplicationSaved',
          components: {
            default: ApplicationSaved,
            Footer
          }
        },
        {
          path: 'get-started',
          name: 'GetStarted',
          components: {
            default: GetStarted,
            Footer,
            Header
          },
          meta: {
            middleware: [checkProductStatus],
            currentStep: 0
          }
        },
        {
          path: '',
          name: 'EmptyWelcome',
          components: {
            default: GetStarted,
            Footer,
            Header
          },
          meta: {
            middleware: [checkProductStatus]
          },
          beforeEnter: (to, from, next) => {
            next({
              name: 'GetStarted',
              params: { spProductSlug: to.params.spProductSlug },
              query: to.query
            });
          }
        },
        {
          path: 'continue',
          name: 'continue',
          components: {
            default: ContinueAccount,
            Footer
          },
          meta: {
            middleware: [checkProductStatus]
          }
        },
        {
          path: 'invite-expired',
          name: 'InviteExpired',
          components: {
            default: InviteExpired,
            Footer
          }
        }
      ]
    }
  ]
});

const pendingNavigation = { value: false };

const safeNavigate = debounce(async (to, from, next) => {
  if (pendingNavigation.value) return;

  pendingNavigation.value = true;
  try {
    await next();
  } catch (err) {
    if (err.name !== 'NavigationDuplicated') {
      console.error('Navigation error:', err);
    }
  } finally {
    pendingNavigation.value = false;
  }
}, 300);

router.beforeEach((to, from, next) => {
  if (to.meta.currentStep !== undefined) {
    sessionStorage.setItem('currentStep', to.meta.currentStep.toString());
  }
  if (to.name === 'ContinueWithSmApplicationId') return next();
  safeNavigate(to, from, next);
});

export default router;